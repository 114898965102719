import './App.css';
import BasicHeader from './common/BasicHeader';
import { AppBar, Toolbar, Typography, Button, Grid, Container, Card, CardContent, CardHeader } from '@material-ui/core';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CheckIcon from '@material-ui/icons/Check'

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Poppins, sans-serif'
  }
})

function App() {
  return (
    <div className="App" style={{ background: 'black' }}>
      <ThemeProvider theme={theme}>
        <div>
          <AppBar position="static" style={{ backgroundColor: 'white', color: '#18191F' }}>
            <BasicHeader />
            <Toolbar>
              {/* <Grid container>
                <Grid item xs={4}>
                  <IconButton edge="start" color="red" aria-label="menu">
                    <Menu />
                  </IconButton>
                  <img src="./logo-horizontal.png" alt="Sensei Poker Horizontal" />
                </Grid>
                <Grid item xs={8}>
                  <ButtonGroup variant="text" style={{ color: '#18191F' }} size='large' aria-label="text primary button group">
                    <Button style={{ padding: '8px 25px' }}>Sobre</Button>
                    <Button style={{ padding: '8px 25px' }}>Clube</Button>
                    <Button style={{ padding: '8px 25px' }}>Time</Button>
                    <Button style={{ padding: '8px 25px' }}>Escola</Button>
                  </ButtonGroup>
                </Grid>
              </Grid> */}
            </Toolbar>
          </AppBar>
          <section style={{ background: "url('./bg1.png') no-repeat", backgroundPosition: "center", backgroundSize: "cover", padding: "64px 8px" }}>
            <Container>
              <Grid container alignItems="center">
                <Grid item xs={12} md={6} style={{ marginBottom: 16 }}>
                  <img style={{ maxWidth: '50%' }} src="./logo-vertical.png" alt="Sensei Poker Vertical" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" style={{ textTransform: 'uppercase', color: 'white', fontSize: 35 }}> Há 5 anos evoluindo a <b>comunidade do poker no Brasil</b></Typography>
                  <a href="https://club.sensei.poker" target="_blank" rel="noreferrer"><Button variant="contained" size="large" style={{ color: '#A30908', backgroundColor: 'white', borderRadius: 20, margin: 16 }}>Cadastre-se Grátis</Button></a>
                </Grid>
              </Grid>
            </Container>
          </section>
          <section id="sobre" style={{ backgroundColor: 'black', color: 'white', position: 'relative' }}>
            <div style={{ height: 2, width: '80%', background: '#cd0201', position: 'absolute', bottom: 45, left: '50%', marginLeft: '-40%', boxShadow: '0px 0px 9px rgb(255 0 0)' }}></div>
            <Grid container>
              <Grid item xs={12} md={4} style={{ background: "url('./club-bg.png') no-repeat", backgroundSize: "cover", minHeight: 300 }}>
                <Typography variant='h3' style={{ marginTop: 160 }}><b>Clube</b></Typography><Typography variant="h6"><b>de Benefícios</b></Typography>
              </Grid>
              <Grid item xs={12} md={4} style={{ background: "url('./team-bg.png') no-repeat", backgroundSize: "cover", minHeight: 300, borderLeft: '1px solid #3a060a', borderRight: '1px solid #3a060a' }}>
                <Typography variant='h3' style={{ marginTop: 160 }}><b>Time</b> </Typography><Typography variant="h6"><b>SenseiKan</b></Typography>
              </Grid>
              <Grid item xs={12} md={4} style={{ background: "url('./school-bg.png') no-repeat", backgroundSize: "cover", minHeight: 300 }}>
                <Typography variant='h3' style={{ marginTop: 160 }}><b>Escola</b> </Typography><Typography variant="h6"><b>Maior Acervo</b></Typography>
              </Grid>
            </Grid>
          </section>
          <section style={{ background: "url('./carbon.png') no-repeat", backgroundColor: 'black', borderBottom: "32px solid black", color: 'white', padding: "32px 0" }}>
            <Container>
              <Grid container spacing={4} justify="space-between">
                <Grid item xs={12} md={5}>
                  <Typography variant="h4" style={{ textTransform: 'uppercase', color: '#FF3939' }}>O Sensei Poker</Typography>
                  <Typography variant="h4" style={{ textTransform: 'uppercase', color: '#FF3939' }}><b>cresceu!</b></Typography>
                  <Typography variant="body1" style={{ margin: 16 }}>O Sensei surgiu como uma das primeiras Escolas de Poker do Brasil. Ao longo dos últimos 5 anos, viemos construindo um rico acervo de conhecimento que estará à sua disposição para aprender e evoluir. Agora, além da escola, temos clube de benefícios e times para você crescer.</Typography>
                </Grid>
                <Grid item xs={12} md={5}>
                  <Typography variant="h4" style={{ textTransform: 'uppercase', color: '#FF3939' }}>Credibilidade</Typography>
                  <Typography variant="h4" style={{ textTransform: 'uppercase', color: '#FF3939' }}><b>e Resultados!</b></Typography>
                  <Typography variant="body1" style={{ marginTop: 16 }}>A experiência e credibilidade na comunidade do poker brasileiro nos permitiu construir uma estrutura de treinamento, ensino e acompanhamento capaz de alavancar seu nível de performance no jogo, rendendo melhores resultados e, consequentemente, maiores lucros!</Typography>
                </Grid>
              </Grid>
            </Container>
          </section>
          <section id="clube" style={{ background: "url('./bg3.jpg')", backgroundPosition: "center", backgroundSize: "cover", color: 'white', padding: 40 }}>
            <Container>
              <Grid container>
                <Grid item xs={12}>
                  <img style={{ maxWidth: '90%', textAlign: 'center' }} src="./logo-club.png" alt="Sensei Club" />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" style={{ textTransform: 'uppercase' }}>Quanto mais você joga pelas plataformas parceiras do Sensei Club, <b>maior sua chance de ganhar + de R$100.000,00 em prêmios!</b></Typography>
                  <div style={{ height: 3, width: '100%', background: 'white', margin: 32 }}></div>
                </Grid>
                <Grid item xs={12} style={{ margin: 32 }}>
                  <Grid container>
                    <Grid item xs={2}>
                      <CheckCircleOutlineIcon fontSize="large" />
                    </Grid>
                    <Grid item xs={10}>
                      <Typography variant="body1" style={{ textAlign: 'left' }}>Nossa plataforma possui mais de 2.000 membros cadastrados, suporte em português e inglês, e total segurança para você entender exatamente como participar de todos os benefícios de fazer parte do Sensei Club.</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} style={{ margin: 32 }}>
                  <Grid container>
                    <Grid item xs={2}>
                      <CheckCircleOutlineIcon fontSize="large" />
                    </Grid>
                    <Grid item xs={10}>
                      <Typography variant="body1" style={{ textAlign: 'left' }}>O rake gerado nas salas vão ser recompensados com tickets para os sorteios de + de R$100.000,00 em prêmios</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} style={{ margin: "62px 0" }}>
                  <Grid container>
                    <Grid item xs={4}>
                      <SaveAltIcon fontSize="large" />
                      <Typography variant="body2">SEJA MAIS LUCRATIVO</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <CreditCardIcon fontSize="large" />
                      <Typography variant="body2">NÃO PAGUE NADA POR ISSO</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <ArrowDownwardIcon fontSize="large" />
                      <Typography variant="body2">DIMINUA SEU RISCO</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <a href="https://club.sensei.poker" target="_blank" rel="noreferrer"><Button variant="contained" size="large" style={{ color: '#A30908', backgroundColor: 'white', borderRadius: 20, margin: 32 }}>Cadastre-se Grátis</Button></a>
                  <Typography variant="body1" style={{ textTransform: 'uppercase', margin: 16 }}>O Sensei Club é nosso <b>Clube de Benefícios.</b></Typography>
                </Grid>

              </Grid>
            </Container>
          </section>
          <section style={{ background: "#121212", padding: 64 }}>
            <Container>
              <Grid container>
                <Grid item xs={12} >
                  <Typography variant="h2" style={{ fontSize: 36, color: '#FF3939', textTransform: 'uppercase', fontWeight: 'bold' }}>+ de R$100.000,00 em prêmios</Typography>
                  <div style={{ height: 3, width: '100%', background: '#fff', margin: 16 }}></div>
                  <Typography variant="h2" style={{ fontSize: 36, color: '#FFF', textTransform: 'uppercase', fontWeight: 'bold' }}>Distribuídos para membros do clube</Typography>
                </Grid>
                <Grid item xs={12} style={{ color: 'white', margin: '64px 0' }}>
                  <Typography variant="body1" style={{ textTransform: 'uppercase' }}>Como membro do Sensei Club, você aproveita benefícios exclusivos e pensados para a comunidade de poker!</Typography>
                  <Typography variant="body1" style={{ textTransform: 'uppercase' }}><b>Quanto mais você joga, maior sua chance de ganhar os prêmios, independente de vitórias ou derrotas.</b></Typography>
                </Grid>
              </Grid>
              <Grid container spacing={4} justify="space-between">
                <Grid item xs={12} md={4}>
                  <Card >
                    <CardHeader style={{ background: 'white' }} avatar={
                      <img src="./partypoker.png" alt="party poker" style={{ width: "100%" }} />
                    }>
                    </CardHeader>
                    <CardContent style={{ textAlign: 'left', color: '#3D3D3D' }}>
                      <Typography variant="h6" style={{ fontWeight: 'bold' }}>Party Poker</Typography>
                      <Typography variant="body1">Ganhe 1 Ticket a cada U$ 10 gerado (Net Gaming Revenue)</Typography>
                      <ul style={{ listStyle: 'none', padding: 0 }}>
                        <li>
                          <Grid container justify="space-between">
                            <Grid item xs={2} style={{ color: '#A30908', textAlign: 'center' }}>
                              <CheckIcon fontSize="large" />
                            </Grid>
                            <Grid item xs={10}>
                              <Typography variant="body1">Clube VIP (até 60% de rakeback direto)</Typography>
                            </Grid>
                          </Grid>
                        </li>
                        <li>
                          <Grid container justify="space-between">
                            <Grid item xs={2} style={{ color: '#A30908', textAlign: 'center' }}>
                              <CheckIcon fontSize="large" />
                            </Grid>
                            <Grid item xs={10}>
                              <Typography variant="body1">Grande oferta de jogos</Typography>
                            </Grid>
                          </Grid>
                        </li>
                        <li>
                          <Grid container justify="space-between">
                            <Grid item xs={2} style={{ color: '#A30908', textAlign: 'center' }}>
                              <CheckIcon fontSize="large" />
                            </Grid>
                            <Grid item xs={10}>
                              <Typography variant="body1">Torneios com grandes prêmios garantidos</Typography>
                            </Grid>
                          </Grid>
                        </li>
                        <li>
                          <Grid container justify="space-between">
                            <Grid item xs={2} style={{ color: '#A30908', textAlign: 'center' }}>
                              <CheckIcon fontSize="large" />
                            </Grid>
                            <Grid item xs={10}>
                              <Typography variant="body1">Grande fluxo de jogadores</Typography>
                            </Grid>
                          </Grid>
                        </li>
                      </ul>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Card >
                    <CardHeader style={{ background: 'white' }} avatar={
                      <img src="./bodog.png" alt="Bodog" style={{ width: "100%" }} />
                    }>
                    </CardHeader>
                    <CardContent style={{ textAlign: 'left', color: '#3D3D3D' }}>
                      <Typography variant="h6" style={{ fontWeight: 'bold' }}>Bodog</Typography>
                      <Typography variant="body1">Ganhe 1 Ticket a cada U$ 10 gerado (Net Gaming Revenue)</Typography>
                      <ul style={{ listStyle: 'none', padding: 0 }}>
                        <li>
                          <Grid container justify="space-between">
                            <Grid item xs={2} style={{ color: '#A30908', textAlign: 'center' }}>
                              <CheckIcon fontSize="large" />
                            </Grid>
                            <Grid item xs={10}>
                              <Typography variant="body1">Mesas anônimas e sem HUD</Typography>
                            </Grid>
                          </Grid>
                        </li>
                        <li>
                          <Grid container justify="space-between">
                            <Grid item xs={2} style={{ color: '#A30908', textAlign: 'center' }}>
                              <CheckIcon fontSize="large" />
                            </Grid>
                            <Grid item xs={10}>
                              <Typography variant="body1">Jogadores Americanos</Typography>
                            </Grid>
                          </Grid>
                        </li>
                        <li>
                          <Grid container justify="space-between">
                            <Grid item xs={2} style={{ color: '#A30908', textAlign: 'center' }}>
                              <CheckIcon fontSize="large" />
                            </Grid>
                            <Grid item xs={10}>
                              <Typography variant="body1">Grande oferta de torneios noturnos</Typography>
                            </Grid>
                          </Grid>
                        </li>
                        <li>
                          <Grid container justify="space-between">
                            <Grid item xs={2} style={{ color: '#A30908', textAlign: 'center' }}>
                              <CheckIcon fontSize="large" />
                            </Grid>
                            <Grid item xs={10}>
                              <Typography variant="body1">A sala mais indicada para os jogadores de Cash Game</Typography>
                            </Grid>
                          </Grid>
                        </li>
                      </ul>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Card  >
                    <CardHeader style={{ background: 'white' }} avatar={
                      <img src="./ggpoker.png" alt="GG Poker" style={{ width: "100%" }} />
                    }>
                    </CardHeader>
                    <CardContent style={{ textAlign: 'left', color: '#3D3D3D' }}>
                      <Typography variant="h6" style={{ fontWeight: 'bold' }}>2bet4win - GGPoker</Typography>
                      <ul style={{ listStyle: 'none', padding: 0 }}>
                        <li>
                          <Grid container justify="space-between">
                            <Grid item xs={2} style={{ color: '#A30908', textAlign: 'center' }}>
                              <CheckIcon fontSize="large" />
                            </Grid>
                            <Grid item xs={10}>
                              <Typography variant="body1">60% de rakeback direto</Typography>
                            </Grid>
                          </Grid>
                        </li>
                        <li>
                          <Grid container justify="space-between">
                            <Grid item xs={2} style={{ color: '#A30908', textAlign: 'center' }}>
                              <CheckIcon fontSize="large" />
                            </Grid>
                            <Grid item xs={10}>
                              <Typography variant="body1">Muita ação em cash games e torneios - 24h/dia</Typography>
                            </Grid>
                          </Grid>
                        </li>
                        <li>
                          <Grid container justify="space-between">
                            <Grid item xs={2} style={{ color: '#A30908', textAlign: 'center' }}>
                              <CheckIcon fontSize="large" />
                            </Grid>
                            <Grid item xs={10}>
                              <Typography variant="body1">Grande volume de recreativos</Typography>
                            </Grid>
                          </Grid>
                        </li>
                        <li>
                          <Grid container justify="space-between">
                            <Grid item xs={2} style={{ color: '#A30908', textAlign: 'center' }}>
                              <CheckIcon fontSize="large" />
                            </Grid>
                            <Grid item xs={10}>
                              <Typography variant="body1">Ótima escolha para jogadores de torneios e de Sit & Go.</Typography>
                            </Grid>
                          </Grid>
                        </li>
                      </ul>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              <Grid container style={{ margin: '32px 0' }}>
                <Grid item xs={12}>
                  <a href="https://club.sensei.poker" target="_blank" rel="noreferrer"><Button variant="contained" size="large" style={{ background: '#A30908', color: 'white', borderRadius: 20 }}>Conhecer todos os benefícios</Button></a>
                </Grid>
              </Grid>
            </Container>
          </section>
          <section id="escola" style={{ background: "url('./escola.png') center red", backgroundSize: 'cover', backgroundColor: '#960101', color: 'white', padding: '86px 0' }}>
            <Container>
              <Grid container> 
                <Grid item xs={12}>
                  <Typography variant="h2">ESCOLA <b>SENSEI POKER</b></Typography>
                </Grid>
              </Grid>
            </Container>
          </section>
          <section style={{ background: "url('./bg-white.png')", padding: "64px 0" }}>
            <Container>
              <Grid container spacing={6} style={{ textAlign: 'left' }}>
                <Grid item xs={12}>
                  <Typography variant="h4" style={{ textTransform: 'uppercase', textAlign: 'center' }}><b>Acervo Completo</b></Typography>
                  <div style={{ width: '100%', height: '4px', background: '#A30908', margin: '32px 0' }}></div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="body2" style={{ margin: '16px 0' }}>Temos um amplo e rico acervo de aulas com os melhores jogadores do cenário brasileiro, fruto de anos de dedicação e a da melhor curadoria de conteúdo.</Typography>
                  <Typography variant="body2">Pensando em guiá-lo ao nível mais avançado de jogo, para você que leva o esporte a sério.</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="body2" style={{ margin: '16px 0' }}>Acesso irrestrito as melhores vídeo aulas para que você tenha flexibilidade de fazer seus horários e estudar quando, onde e como quiser.</Typography>
                  <Typography variant="body2">Todo o know-how dos maiores players de forma prática para você que quer investir em elevar o nível do seu jogo.</Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
              <a href="https://escola.sensei.poker" target="_blank" rel="noreferrer"><Button variant="contained" size="large" style={{ background: '#A30908', color: 'white', borderRadius: 20, margin: '64px 0' }}>Quero estudar na melhor escola</Button></a>
              </Grid>
            </Container>
          </section>
          <section style={{ background: "url('./instagram-bg.png')", backgroundSize: 'cover', backgroundPosition: 'center', padding: "64px 0", color: 'white' }}>
            <Container>
              <Grid container alignItems="center" justify="center">
                <Grid item>
                <a href="https://instagram.com/sensei.poker" target="_blank" rel="noreferrer"><img src="./instagram-logo.png" alt="Instagram" /></a>
                </Grid>
                <Grid item>
                <a href="https://instagram.com/sensei.poker" target="_blank" rel="noreferrer"><Typography variant="h4" style={{ fontWeight: 'bold' }}> @SENSEI.POKER</Typography></a>
                </Grid>
              </Grid>
              <Grid container justify="center">
                <Grid item xs={12}>
                  <a href="https://instagram.com/sensei.poker" target="_blank" rel="noreferrer"><img style={{ maxWidth: '90%', marginTop: 32 }} src="./instagram-feed.png" alt="Instagram Feed" /></a>
                </Grid>
              </Grid>
            </Container>
          </section>
          <section style={{ background: "url('./bg-white.png')", padding: "64px 0", color: '#A30908' }}>
            <Container>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h5">CADASTRE-SE</Typography>
                  <Typography variant="h5"><b>QUANTO MAIS VOCÊ JOGAR, MAIS DINHEIRO IRÁ GANHAR!</b></Typography>
                </Grid>
                <Grid item xs={12}>
                  <a href="https://club.sensei.poker" target="_blank" rel="noreferrer"><Button variant="contained" size="large" style={{ background: '#A30908', color: 'white', borderRadius: 20, margin: "32px 0" }}>Quero entrar para o club</Button></a>
                </Grid>
              </Grid>
            </Container>
          </section>
          <section style={{ background: "url('./instagram-bg.png')", backgroundSize: 'cover', backgroundPosition: 'center', padding: "64px 0", color: 'white' }}>
            <Container>
              <Grid container justify="center">
                {/* <Grid item xs={8}>
                  <Grid container spacing={3} className="footerAlign">
                    <Grid item xs={12} md={4}>
                      <Typography variant="body1"><b>SENSEI POKER CLUB</b></Typography>
                      <ul style={{ listStyle: 'none', padding: 0 }}>
                        <li>Sobre</li>
                        <li>FAQ</li>
                        <li>Contato</li>
                      </ul>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography variant="body1"><b>SENSEIKAN</b></Typography>
                      <ul style={{ listStyle: 'none', padding: 0 }}>
                        <li>Sobre</li>
                        <li>FAQ</li>
                        <li>Contato</li>
                      </ul>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography variant="body1"><b>ESCOLA</b></Typography>
                      <ul style={{ listStyle: 'none', padding: 0 }}>
                        <li>Sobre</li>
                        <li>FAQ</li>
                        <li>Contato</li>
                      </ul>
                    </Grid>
                  </Grid>
                </Grid> */}
                <Grid item xs={12} md={4}>
                  <img src="./senseipoker.png" alt="Sensei Poker" style={{ maxWidth: '100%' }} />
                </Grid>
              </Grid>
            </Container>
          </section>
        </div>
      </ThemeProvider>
    </div>
  );
}

export default App;
