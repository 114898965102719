import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-scroll'

const useStyles = makeStyles((theme) => ({

  itemTitle: {
    fontSize: '12px',
    color: '#444',
    textTransform: 'uppercase'
  },
  nestedItemTitle: {
    fontSize: '12px',
    color: '#444',
    paddingLeft: 8
  },
  menuIcon: {
    width: '15px',
    position: 'absolute',
    top: '16px',
    right: '16px'
  }
}));

const MenuList = (props) => {

  const classes = useStyles();
  return(
    <List>
      <Link to="sobre" spy={true} smooth={true}><ListItem button  onClick={()=>  props.onClose() } style={{margin: '10px 0'}}>
        <ListItemText className={classes.itemTitle} primary={<Typography className={classes.itemTitle}>SOBRE</Typography>} />
      </ListItem></Link>
      <Link to="clube" spy={true} smooth={true}><ListItem  button onClick={()=> props.onClose()  } style={{margin: '10px 0'}}>
        <ListItemText className={classes.itemTitle} primary={<Typography className={classes.itemTitle}>CLUBE</Typography>} />
      </ListItem></Link>
      <Link to="time" spy={true} smooth={true}><ListItem  button onClick={()=>  props.onClose() } style={{margin: '10px 0'}}>
        <ListItemText className={classes.itemTitle} primary={<Typography className={classes.itemTitle}>TIME</Typography>} />
      </ListItem></Link>
      <Link to="escola" spy={true} smooth={true}><ListItem button onClick={()=>  props.onClose() } style={localStorage.getItem('firstLogin')==='true' ? {background: '#5454de', color:'#fff', margin: '10px 0'} : {margin: '10px 0'}}>
        <ListItemText className={classes.itemTitle} primary={<Typography className={classes.itemTitle} style={localStorage.getItem('firstLogin')==='true' ? {color:'#fff'} : {}}>ESCOLA</Typography>} />
      </ListItem></Link>
    </List>
  )
}

export default MenuList