import React, {Component} from 'react'
import { AppBar, Box, Grid, Button, ButtonGroup, Container } from '@material-ui/core';
import SideMenu from '../common/SideMenu';
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from 'react-scroll'


class BasicHeader extends Component{

  state = {
    user: {},
    isUserInfoOpen: false,
    menuOpen: false
  }

  headerStyle = {
    height: 60,
    background: '#fff',
    color: '#18191F',
    display: 'flex',
    justifyContent: 'space-between',
    boxShadow: '2px 2px 8px #9e9e9e'
  }

  logoStyle = {
    marginLeft: '16px',
    width: '120px',
    marginTop: 12
  }

  contentStyle ={
    marginTop: '2px'
  }

  authInfofStyle ={
    background: 'white',
    color: '#444',
    borderRadius: 0
  }

  accordionStyle ={
    borderRadius: 0,
    dropShadow: '2px 2px 2px #444',
    color: '#444'
  }
  
  usernameStyle ={
    fontSize: '12px',
    fontWeight: '700',
    color: '#444',
    marginBottom: '0'
  }

  menuIconStyle={
    color: 'black',
    display: 'block',
    fontSize: '24px',
    height: '100%'
  }

  handleOpenUserInfo() {
    this.setState((state)=>({
      isUserInfoOpen: !this.state.isUserInfoOpen
    }))
  }

  handleCloseMenu = () => {
    this.setState({menuOpen: false})
  }

  handleOpenMenu = () => {
    this.setState({menuOpen: true})
  }

  render() {
    const {selectedMenu} = this.props
    return(
      <AppBar style={{position: 'fixed', width: '100%', zIndex: '1300'}}>
        <SideMenu selected={selectedMenu} open={this.state.menuOpen} handleCloseMenu={this.handleCloseMenu} />
        <header style={this.headerStyle}>
          <Box component="div" display={{ xs: 'inline-flex', sm: 'none' }} onClick={this.handleOpenMenu}>
            <div style={{background: 'white', padding: '10px'}}>
              <MenuIcon style={this.menuIconStyle} />
            </div>
            <div style={this.contentStyle}>
              <img style={this.logoStyle} alt="logo" src={process.env.PUBLIC_URL + '/logo-horizontal.png'}/>
            </div>
          </Box>
          <Box component="div" display={{ xs: 'none', sm: 'inline-flex' }} style={{ width: '100%'}}>
            <Container>
              <Grid container alignItems="center" justify="space-between" spacing={6}>
                  <Grid item xs={4} style={{ textAlign: 'left'}}>
                    <img style={this.logoStyle} alt="logo" src={process.env.PUBLIC_URL + '/logo-horizontal.png'}/>
                  </Grid>
                  <Grid item xs={8} style={{ textAlign: 'right' }}>
                    <ButtonGroup variant="text" style={{ color: '#18191F', marginTop: 8 }} size='large' aria-label="text primary button group">
                      <Link to="sobre" spy={true} smooth={true}><Button style={{ padding: '8px 25px' }}>Sobre</Button></Link>
                      <Link to="clube" spy={true} smooth={true}><Button style={{ padding: '8px 25px' }}>Clube</Button></Link>
                      <Link to="time" spy={true} smooth={true}><Button style={{ padding: '8px 25px' }}>Time</Button></Link>
                      <Link to="escola" spy={true} smooth={true}><Button style={{ padding: '8px 25px' }}>Escola</Button></Link>
                    </ButtonGroup>
                  </Grid>
              </Grid>
            </Container>
          </Box>
        </header>
        
      </AppBar>
    )
  }
}


export default BasicHeader