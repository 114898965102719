import React from 'react'
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import MenuList from './MenuList';

const useStyles = makeStyles((theme) => ({
  
  drawer: {
    width: '220px',
    flexShrink: 0
  }
}));

const SideMenu = (props) => {

    const classes = useStyles();

    const handleCloseMenu = () => {
      props.handleCloseMenu()
    }
    
    return(
      <Drawer
        className={classes.drawer}
        open={props.open}
        onClose={props.handleCloseMenu}
        anchor="left"
        classes={{
          paper: classes.drawer,
        }}
      >
        <div style={{height: '60px', marginLeft:"10px", marginTop:"16px", fontSize:"24px"}} onClick={handleCloseMenu}>
          <MenuIcon className={classes.menuIcon} />
        </div>
        <MenuList selected={props.selected} onClose={props.handleCloseMenu} />
      </Drawer>
    )
}


export default SideMenu